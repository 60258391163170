import { autobind, debounce } from "core-decorators";
import { GLOBAL_CONSTANTS } from "../globals/constants";
import { EventBus } from "../globals/emitter";
import { moveTo } from "../effects/animation/animations";
import { IAnimation } from "../effects/animation/i-animation";
import "whatwg-fetch";

declare var gtm: any;

const constants = {
  searchInputId: ".js-ind-search-input",
  searchResult: ".js-ind-search-result",
  searchResults: ".js-ind-search-results",
  searchResultsText: ".js-ind-search-results-text",
  searchEmpty: ".js-ind-search-empty",
  loadMore: ".js-ind-search-load-more",
  loader: ".js-ind-search-loader",
  moreButton: ".js-ind-load-more",
  container: ".js-pages-found",
  hide: "-hide",
  show: "-show-results",

  positions: {
    start: "50%",
    end: "25%",
  },
};

function htmlEncode(str: string): string {
  return str.replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#39;");
}

/**
 * @desc Handle search page functionality.
 */
export class IndependentSearch {
  $el: HTMLElement;
  $input: HTMLInputElement;
  $textResults: HTMLElement;
  $pageType: HTMLInputElement;
  $loadMore: HTMLElement;
  $moreButton: HTMLElement;
  $noResults: HTMLElement;
  $loader: HTMLElement;
  $searchInput: HTMLInputElement;
  $resultsWrapper: HTMLElement;
  $container: HTMLElement;
  resultsShowing: boolean;
  inputAnimation: IAnimation;
  query = "";
  numberOfItemsToSkip = 0;
  dataUrl = "/api/search/independentsearch/";
  searchPageType = "";
  parentCategories = "";
  childCategories = "";
  maxNumberOfResults = 5;
  excludePdf = false;
  gtmSearchType = "internal";
  html = "";

  constructor(el: HTMLElement) {
    this.$el = el;
    this.$input = this.$el.querySelector(
      constants.searchInputId,
    ) as HTMLInputElement;
    this.$searchInput = this.$input.querySelector("input");
    this.$pageType = this.$input.querySelector(
      "input[type=hidden]",
    ) as HTMLInputElement;
    this.$loadMore = this.$el.querySelector(constants.loadMore) as HTMLElement;
    this.$moreButton = this.$el.querySelector(
      constants.moreButton,
    ) as HTMLElement;
    this.$textResults = this.$el.querySelector(
      constants.searchResultsText,
    ) as HTMLElement;
    this.$noResults = this.$el.querySelector(
      constants.searchEmpty,
    ) as HTMLElement;
    this.$loader = this.$el.querySelector(constants.loader) as HTMLElement;
    this.$resultsWrapper = this.$el.querySelector(
      constants.searchResults,
    ) as HTMLElement;
    this.$container = this.$el.querySelector(
      constants.container,
    ) as HTMLElement;
    this.inputAnimation = moveTo(
      this.$input,
      constants.positions.start,
      constants.positions.end,
    );
    this.resultsShowing = false;
    this.init();
  }

  init(): void {
    this.dataUrl = this.$el.dataset["url"] || this.dataUrl;
    this.bindEvents();
  }

  /**
   * @desc Set up events for each step
   */
  @autobind
  bindEvents(): void {
    this.$searchInput.addEventListener("keyup", this.onKeyUp);
    EventBus.on(GLOBAL_CONSTANTS.EVENTS.SEARCH.POPULATE, this.populateSearch);
    EventBus.on(GLOBAL_CONSTANTS.EVENTS.MODAL_CLOSE, this.clearInput);
    this.$moreButton.addEventListener("click", this.loadMore);
  }

  /**
   * @desc hide the search results
   */
  @autobind
  hideResults(): void {
    this.resultsShowing = false;
    this.$resultsWrapper.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
    this.$noResults.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
  }

  /**
   * @desc clear search input
   */
  @autobind
  clearInput(): void {
    this.$searchInput.value = "";
    this.hideResults();
  }

  @autobind
  populateSearch(query: string) {
    this.$searchInput.value = query;
    this.query = htmlEncode(query);
    this.handleKeyPress(query);
  }

  @autobind
  loadMore(): void {
    const currentlyShown = parseInt(this.$moreButton.dataset.shown, 10);
    const toShow = currentlyShown + this.maxNumberOfResults;
    const total = parseInt(this.$moreButton.dataset.total, 10);
    const $resultItems = document.querySelectorAll(".js-search-result");
    const $resultsFound = document.querySelector(".js-results-found");
    if (toShow < total) {
      this.$moreButton.dataset.shown = (
        currentlyShown + this.maxNumberOfResults
      ).toString();
      $resultsFound.innerHTML = toShow + " of " + total + " results found";
    } else {
      this.$moreButton.dataset.shown = this.$moreButton.dataset.total;
      this.$loadMore.classList.add(constants.hide);
      $resultsFound.innerHTML = total + " of " + total + " results found";
    }
    for (let i = 0; i < toShow; i++) {
      const item = $resultItems[i] as HTMLElement;
      item.classList.remove(constants.hide);
    }
  }

  /**
   * @desc actions called onkeyup in search input
   */
  @autobind
  onKeyUp(): void {
    const inputVal = this.$searchInput.value;
    if (inputVal !== this.query) {
      this.query = htmlEncode(inputVal);
      this.handleKeyPress(inputVal);
    }
  }

  /**
   * @desc Run query / hide old results
   * @param {string} inputVal - value to query
   */
  @debounce(700)
  handleKeyPress(inputVal: string): void {
    this.hideResults();
    if (inputVal.length > 0) {
      this.$loader.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE);
      this.numberOfItemsToSkip = 0;
      this.search();
    }
  }

  /**
   * @desc Query items, animate content in.
   * @param {string} query - string to search for
   */
  @autobind
  search(): void {
    const url = this.dataUrl + this.$pageType.value + `?keywords=${htmlEncode(this.query)}`;
    this.$resultsWrapper.classList.add(constants.show);
    this.$loader.classList.remove(constants.hide);
    this.$container.classList.add(constants.hide);
    this.$loadMore.classList.add(constants.hide);
    this.$noResults.classList.add(constants.hide);
    this.html = "";
    let gtmData = `'navAction':'click','navCall':'','navDirections':'','navElement':'','navExternal':'','navId':'','navLink':'',`;
    gtmData = `'navReferrer':'${url}','navSocial':'','hostName':'www.umpquabank.com','path':'${this.$pageType.value}','url':'${url}'`;
    fetch(url, {
      credentials: "include",
      method: "GET",
    })
      .then(this.checkStatus)
      .then((response: any) => {
        response.text().then((results: any) => {
          const resultsObj = JSON.parse(results);
          let resultsList = new Array();
          let count = 1;
          resultsList = resultsObj.results;
          const resultsCountText =
            resultsObj.resultCount < this.maxNumberOfResults
              ? resultsObj.resultCount +
                " of " +
                resultsObj.resultCount +
                " results found"
              : "5 of " + resultsObj.resultCount + " results found";
          if (resultsObj.resultCount > 0) {
            this.$noResults.classList.add(constants.hide);
            this.html += `<div class="results-found js-results-found">${resultsCountText}</div><ul class="unordered-list js-results-container">`;
            for (const result in resultsList) {
              const pageLink = resultsList[parseInt(result, 10)].pageLink;
              const pageName = resultsList[parseInt(result, 10)].pageName;
              const metaDataDescription =
                resultsList[parseInt(result, 10)].metaDataDescription ===
                  null ||
                resultsList[parseInt(result, 10)].metaDataDescription === "null"
                  ? ""
                  : resultsList[parseInt(result, 10)].metaDataDescription;
              const uniquId = htmlEncode(pageName)
                .toLowerCase()
                .trim();
              const gtmMouseAction = `gtm.navigation.linkJson(${gtmData}, '${pageLink}', '${pageName}', this, event);`;
              const gtmKeyAction = `gtm.navigation.linkKeyDownJson(${gtmData}, '${pageLink}', '${pageName}', this, event);`;
              const hide =
                count > this.maxNumberOfResults ? constants.hide : "";
              const htmlItem = `<li class="results-list js-search-result ${hide}">
                                    <div class="color-bar -child-background-color"></div>
                                    <a id="search-help-center-${uniquId}" class="text-result" href="${pageLink}" onmousedown="${gtmMouseAction}" onkeydown="${gtmKeyAction}">
                                    <h3 class="title">${pageName}</h3>
                                    <p class="text text-1">${metaDataDescription}</p>
                                    </a></li>`;
              this.html += htmlItem;
              count++;
            }
            this.html += `</ul>`;
            this.$container.innerHTML = this.html;
            this.$loader.classList.add(constants.hide);
            this.$container.classList.remove(constants.hide);
            if (resultsObj.resultCount > this.maxNumberOfResults) {
              this.$loadMore.classList.remove(constants.hide);
            }
            this.$moreButton.dataset.shown = this.maxNumberOfResults.toString();
            this.$moreButton.dataset.total = resultsObj.resultCount.toString();
          } else {
            this.$loader.classList.add(constants.hide);
            this.$loadMore.classList.add(constants.hide);
            this.$noResults.classList.remove(constants.hide);
          }
        });
      })
      .catch((error: any) => {
        gtm.events.json(
          "search-" + this.gtmSearchType,
          this.$searchInput.placeholder,
          "api search error - " + error,
        );
        console.log("request failed", error);
      });
  }
  
  @autobind
  checkStatus(response: any): any {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      gtm.events.json(
        "search-" + this.gtmSearchType,
        this.$searchInput.placeholder,
        "api search error - status " + response.status,
      );
      throw new Error(`Status: ${response.statusText} Response: ${response}`);
    }
  }

  /**
   * @desc Remove event listeners
   */
  tearDown(): void {
    this.$searchInput.removeEventListener("keyup", this.onKeyUp);
    EventBus.off(GLOBAL_CONSTANTS.EVENTS.MODAL_CLOSE, this.clearInput);
  }
}
